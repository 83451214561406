import * as Dialog from "@radix-ui/react-dialog"
import { m } from "framer-motion"
import React, { FC } from "react"
import { IconButton } from "../button/IconButton"
import * as styles from "./styles"

export type OverlayProps = {
  title: string
  headerChild?: React.ReactElement
  trigger?: React.ReactElement
  leftHeaderButton?: React.ReactElement
  animateFrom?: "bottom" | "right"
  onOpenChange?: (open: boolean) => void
  hideHeaderBorders?: boolean
  open?: boolean
}

export const Overlay: FC<OverlayProps> = ({
  trigger,
  title,
  headerChild,
  children,
  leftHeaderButton,
  animateFrom = "right",
  open,
  onOpenChange,
  hideHeaderBorders = false,
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content
          className={styles.sidebar}
          data-animate-from={animateFrom}
        >
          <aside className={styles.grid}>
            <div
              className={styles.container}
              data-hide-border={hideHeaderBorders ? "" : undefined}
            >
              <div
                className={styles.titleSection}
                data-hide-border={
                  headerChild || hideHeaderBorders ? "" : undefined
                }
              >
                {leftHeaderButton && leftHeaderButton}
                <m.div
                  layout
                  transition={{
                    duration: 0.15,
                  }}
                >
                  <h5 className={styles.heading}>{title}</h5>
                </m.div>
                <Dialog.Close className={styles.close}>
                  <IconButton
                    as="span"
                    size="small"
                    variant="secondary"
                    icon="close"
                    label={`Close ${title}`}
                  />
                </Dialog.Close>
              </div>
              {headerChild && (
                <div className={styles.headerChild}>{headerChild}</div>
              )}
            </div>
            <div className={styles.childContainer}>{children}</div>
          </aside>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
