import { parse } from "@config/theme";
import { css } from "@linaria/core";
import { rem } from "polished";
export const dotContainer = parse({
  position: "relative"
}, "d160r2qk");
export const dotTrack = parse({
  position: "absolute",
  display: "grid",
  alignItems: "center"
}, "ddgmpq3");
export const dot = parse({
  bg: "shade400",
  display: "block"
}, "djnkyti");
export const container = "c121vqll";

require("./SliderIndicators.styles.linaria.module.css!=!../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SliderIndicators.styles.ts");