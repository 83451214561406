import React, { FC } from "react"
import { Icon, IconProps } from "./Icon"

export const IconChevronRight: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13 25l8-9-8-9"
      ></path>
    </Icon>
  )
}

export default IconChevronRight
