import React, { FC, useEffect, useState } from "react"
import * as styles from "./SliderIndicators.styles"

type SliderIndicatorsProps = {
  amount: number
  activeIndex: number
}

const MAX_VISIBLE = 5
const DOTWIDTH = 14

export const SliderIndicators: FC<SliderIndicatorsProps> = ({
  amount,
  activeIndex,
}) => {
  const [leftPositionTrack, setLeftPositionTrack] = useState(0)

  useEffect(() => {
    // Only transform x position of track if there are more than MAX_VISIBLE slides
    if (activeIndex > 1 && amount > MAX_VISIBLE && activeIndex < amount - 2) {
      setLeftPositionTrack(activeIndex - 2)
    }
  }, [activeIndex]) // eslint-disable-line

  return (
    <div className={styles.container} style={{ width: getContainerWidth() }}>
      <div
        className={styles.dotContainer}
        style={{ width: getContainerWidth() }}
      >
        <div
          className={styles.dotTrack}
          style={{
            width: getTrackWidth(),
            transform: `translateX(-${leftPositionTrack * DOTWIDTH}px)`,
          }}
        >
          {[...Array(amount)].map((_, index) => (
            <span
              className={styles.dot}
              data-size={getDotSize(index)}
              key={index}
              data-active={activeIndex === index ? "" : undefined}
            />
          ))}
        </div>
      </div>
    </div>
  )

  function getDotSize(dotIndex: number) {
    // Only animate dots if there are more than MAX_VISIBLE slides
    if (amount > MAX_VISIBLE) {
      if (activeIndex > 2 && dotIndex > 0 && activeIndex < amount - 3) {
        if ([activeIndex - 1, activeIndex + 1].includes(dotIndex)) {
          return "small"
        } else if ([activeIndex - 2, activeIndex + 2].includes(dotIndex)) {
          return "tiny"
        }
      }

      if (activeIndex < amount - 3) {
        // If first 5 slides are visible, make sure the 4th and 5th are downsized
        if (activeIndex < 3) {
          if (dotIndex === MAX_VISIBLE - 2) {
            return "small"
          } else if (dotIndex === MAX_VISIBLE - 1) {
            return "tiny"
          }
        }
      } else {
        if (dotIndex === amount - 4) {
          return "small"
        } else if (dotIndex === amount - 5) {
          return "tiny"
        }
      }
    }
  }

  function getContainerWidth() {
    const dotCount = amount < MAX_VISIBLE ? amount : MAX_VISIBLE
    return DOTWIDTH * dotCount
  }

  function getTrackWidth() {
    return DOTWIDTH * MAX_VISIBLE > amount ? MAX_VISIBLE : amount
  }
}
