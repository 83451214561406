import { SanityImageSource } from "@sanity/asset-utils"
import sanityClient from "@sanity/client"
import { h } from "@sanity/block-content-to-html"
import imageUrlBuilder from "@sanity/image-url"

export const sanityOptions = {
  projectId: "5qrdd1a6",
  dataset: "production",
  apiVersion: new Date().toISOString().slice(0, 10),
  fetch: fetch,
}

const client = sanityClient({
  ...sanityOptions,
  useCdn: true,
})

const previewClient = sanityClient({
  ...sanityOptions,
  useCdn: false,
  withCredentials: true,
  token: process.env.NEXT_PUBLIC_SANITY_TOKEN,
})

export function getSanityClient(preview = false) {
  if (preview || process.env.NODE_ENV === "development") {
    return previewClient
  }
  return client
}

const builder = imageUrlBuilder(sanityOptions)

export function urlFor(source) {
  return builder.image(source)
}

export const getDrafts = (docs: any[], preview = false) => {
  if (process.env.NODE_ENV === "development" || preview) {
    const drafts = docs.filter((doc) => doc._id.startsWith("drafts."))
    return drafts.length ? drafts : docs
  }
  return docs
}
type Dimensions = { width: number; height: number }
export function resizeDimensions(width: number, dimensions: Dimensions) {
  const percentage = (width * 100) / dimensions.width / 100
  return {
    width: Math.round(dimensions.width * percentage),
    height: Math.round(dimensions.height * percentage),
  }
}

export function blurImage(source: SanityImageSource): string {
  const image = builder
    .image(source)
    .width(64)
    .quality(30)
    .blur(50)
    .fit("clip")
    .toString()
  if (image === null && process.env.NODE_ENV === "development") {
    console.warn(`Could not create image from `, source)
  }
  return image || ""
}

export const serializers = {
  marks: {
    link: (props) =>
      h(
        "a",
        {
          href: props.mark.href,
          target: props.mark.blank ? "_blank" : "",
        },
        props.children
      ),
  },
}
