import { parse, parseAll, theme, Theme } from "@config/theme";
import { css, cx } from "@linaria/core";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import React, { ButtonHTMLAttributes } from "react";
import { ThemeSystemProps } from "theme-system";
import { basics } from "./styles";
type CleanIconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & Pick<ThemeSystemProps<Theme>, "ml" | "mr"> & {
  variant?: "grey-to-white" | "white-to-white";
  label: string;
};
export const CleanIconButton = React.forwardRef<HTMLButtonElement, CleanIconButtonProps>(({
  children,
  className,
  variant = "grey-to-white",
  ml,
  mr,
  label,
  ...rest
}, ref) => <button className={cx(basics, button, parseAll({
  ml,
  mr
}), className)} data-variant={variant} ref={ref} {...rest}>
      <VisuallyHidden.Root>{label}</VisuallyHidden.Root>
      {children}
    </button>);
CleanIconButton.displayName = "CleanIconButton";
const button = parse({
  p: "4",
  position: "relative"
}, "b1yw1g56");

require("./CleanIconButton.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./CleanIconButton.tsx");