import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rgba } from "polished";
export const overlay = parse({
  position: "fixed",
  height: "100%",
  width: "100%"
}, "on7apj0");
export const heading = "h1d6emfx";
export const childContainer = "c123go76";
export const grid = parse({
  display: "grid",
  position: "relative",
  height: "100%",
  width: "100%"
}, "grj3a8b");
export const close = "c106vkm1";
export const sidebar = parse({
  position: "fixed",
  height: "100%",
  width: "100%",
  bg: "sidebar"
}, "swy8xx8");
export const container = parse({
  textAlign: "center"
}, "c1tsxjh4");
export const titleSection = parse({
  pt: "16",
  pb: "16",
  px: "20",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start"
}, "t10vz6xe");
export const headerChild = parse({
  px: "20",
  py: "4",
  mb: "20"
});

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");