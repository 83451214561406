import React, { FC } from "react"
import { Icon, IconProps } from "./Icon"

export const IconMenu: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M6.5 7.2h19M9 16h13.4M6.5 24.8h19"
      ></path>
    </Icon>
  )
}

export default IconMenu
